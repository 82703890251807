.coord {
  position: absolute;
  right: 6.3%;
  z-index: 10;

  &__elem {
    width: 1px;
    height: 57px;
    font-family: "sf", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: #c4c4c4;
    background-color: #c4c4c4;

    &::before {
      position: absolute;
      left: 50%;
      top: -40%;
      transform: translateX(-50%);
    }

    &::after {
      position: absolute;
      left: 50%;
      bottom: -40%;
      transform: translateX(-50%);
    }
  }

  @include mobile {
    display: none;
  }
}
