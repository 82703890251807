.advantage-item--3 {
  .advantage-item-left {
    width: 53%;

    @include small-tablet {
      width: 100%;
    }

    &__title {
      width: 80%;
    }
  }

  .advantage-item-right {
    width: 47%;
    margin-top: -36px;

    &__list {
      @include small-tablet {
        display: flex;
        flex-flow: column wrap;
        width: 100%;
      }
    }

    &__elem {
      padding-left: 292px;
      padding-top: 30px;
      padding-bottom: 30px;
      background-position: 36.6% center;

      &:not(:last-child) {
        margin-bottom: 47.5px;

        @include high-desktop {
          margin-bottom: 20px;
        }

        @include small-tablet {
          margin-bottom: 15px;
        }
      }

      &:nth-child(1) {
        background-image: url("../img/adv-3-elem-1.svg");
      }

      &:nth-child(2) {
        background-image: url("../img/adv-3-elem-2.svg");
      }

      &:nth-child(3) {
        background-image: url("../img/adv-3-elem-3.svg");
      }

      &:nth-child(4) {
        background-image: url("../img/adv-3-elem-4.svg");
      }

      &:before {
        top: 50%;
        width: 149px;
      }

      @include high-desktop {
        padding-left: 230px;
        background-position: 39.5% center;
      }

      @include small-tablet {
        padding: 20px 0;
        padding-left: 50px;
        background-position: left center;
        background-size: 38px 38px;
      }
    }

    @include small-tablet {
      width: 100%;
      margin-top: 0;
    }
  }
}
