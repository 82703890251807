.burger {
  @include burger;

  z-index: 100;
  display: none;

  @include high-tablet {
    display: block;
  }
}

