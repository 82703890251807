.packet {
  position: relative;
  padding-top: 189px;

  &__img {
    position: absolute;
    right: 0;
    top: 3.4%;
    width: 860px;

    @include high-desktop {
      top: center;
      width: 400px;
    }

    @include mobile {
      display: none;
    }
  }

  &__title {
    margin-bottom: 108px;

    @include mobile {
      margin-bottom: 30px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 780px;

    @include mobile {
      width: 100%;
    }
  }

  &-item {
    z-index: 10000000;
    position: relative;
    margin-right: 16px;
    margin-bottom: 45px;
    width: 370px;

    &__count {
      z-index: -1;
      position: absolute;
      left: -36.4%;
      top: 3%;
      font-weight: 900;
      font-size: 96px;
      line-height: 116px;
      text-align: right;
      text-transform: uppercase;
      color: var(--alto-color);
      opacity: 0.6;

      @include high-desktop {
        left: 0;
        top: 0;
      }
    }

    &:nth-child(1) {
      margin-right: 50px;

      @include high-desktop {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      display: block;

      @include high-desktop {
        margin-right: 0;
      }
    }

    &:nth-child(3) {
      display: block;

      @include high-desktop {
        margin-right: 0;
      }
    }

    &__title {
      margin-bottom: 36px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--dark-color);
    }

    @include high-desktop {
      width: 50%;
    }

    @include small-tablet {
      width: 100%;
    }
  }

  &-coord {
    top: 14%;

    &__elem {
      &:before {
        content: "07"
      }

      &:after {
        content: "08"
      }
    }
  }

  @include high-desktop {
    padding-top: 100px;
  }

  @include small-tablet {
    padding-top: 50px;
  }
}
