.owner {
  position: relative;
  padding-top: 285px;
  padding-bottom: 300px;
  max-height: 983px;
  background: url("../img/owner.png") -112.1% 1.4% / auto no-repeat;

  &__container {
    display: flex;
    justify-content: end;
  }

  &-content {
    margin-right: 59px;
    width: 45%;

    &__title {
      margin-bottom: 64px;
    }

    &__descr-wrapper {
      width: 100%;
      margin-bottom: 105px;

      @include mobile {
        margin-bottom: 30px;
      }
    }

    &__descr {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-name {
      span {
        display: block;
      }

      &__main-text {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: var(--dark-color);
      }

      &__secondary-text {
        font-size: 14px;
        line-height: 17px;
        color: #d39804;
      }
    }

    @include small-tablet {
      width: 100%;
    }

    @include mobile {
      margin: 0;
    }
  }

  &-coord {
    top: 26%;

    &__elem {
      &:before {
        content: "05"
      }

      &:after {
        content: "06"
      }
    }
  }

  @include high-desktop {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 50px;
    background-size: contain;
    background-position: -80% center;
  }

  @include tablet {
    background-size: 630px 600px;
    background-position: -40% bottom;
  }

  @include small-tablet {
    padding-bottom: 0;
    height: 1000px;
    background-size: 630px 600px;
    background-position: 150% bottom;
  }

  @include mobile {
    background-size: 450px 450px;
    background-position: center 110%;
  }
}
