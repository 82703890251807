.header {
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 58px 0;

  &__container {
    @include flex-all-sb;
  }

  &-logo {
    @include flex-all-sb;

    &__link {
      display: inline-block;
      margin-right: 49px;
    }

    &__descr {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--light-color);

      @include mobile {
        display: none;
      }
    }
  }

  &-contacts {
    @include flex-all-sb;

    &__item {
      padding: 10px 0;
      padding-left: 35px;
      background-size: auto;
      background-position: 0 center;
      background-repeat: no-repeat;
      transition: color 0.1s ease-in-out;

      &:first-child {
        margin-right: 32px;
        background-image: url("../img/c-tel.svg");

        @include high-tablet {
          margin-right: 0;
        }
      }

      &:last-child {
        background-image: url("../img/c-mail.svg");
      }
    }

    &__link {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: var(--light-color);

      &:hover {
        color: var(--orange-color);
        transition: color 0.1s ease-in-out;
      }
    }

    @include high-tablet {
      @include flex-v-center;

      position: fixed;
      left: 0;
      top: 0;
      overflow-y: auto;
      z-index: 50;
      flex-direction: column-reverse;
      justify-content: center;
      width: 100%;
      height: 100vh;
      background: var(--dark-color);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
  }

  @include small-tablet {
    padding: 30px 0;
  }
}

.menu--active {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}


