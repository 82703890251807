.results {
  position: relative;
  padding-top: 5px;
  padding-bottom: 119px;

  &__content {
    padding-left: max(15px, calc((100% - 1170px) / 2));
  }

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: var(--gray-color);
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 53px;

    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include small-tablet {
        justify-content: start;
        margin-bottom: 30px;
        width: 100%;
      }
    }

    &__right {
      display: flex;
      align-items: end;
      justify-content: end;

      span {
        display: flex;
        justify-content: end;
        max-width: 412px;
        padding-left: 45px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--green-color);
        background: url("../../img/check.svg") 0 center / auto no-repeat;
      }
    }

    @include small-tablet {
      flex-wrap: wrap;
    }
  }

  &-swiper {
    &__btn-wrapper {
      margin-right: 40px;
    }

    &-slide {
      width: initial;

      &__list {
        display: flex;
        flex-flow: column wrap;
      }

      &__item {
        &:not(:last-child) {
          margin-right: 105px;
        }
      }

      &__main-text {
        display: block;
        margin-bottom: 8px;
        font-weight: 900;
        font-size: 206px;
        line-height: 249px;
        text-transform: uppercase;
        color: var(--dark-color);

        &--yt {
          font-size: 109px;
          line-height: 131.91px;
          color: var(--orange-color);

          @include small-tablet {
            font-size: 60px;
            line-height: 70px;
          }

          @include mobile {
            font-size: 40px;
            line-height: 55px;
          }
        }

        @include high-desktop {
          font-size: 106px;
          line-height: 149px;
        }

        @include small-tablet {
          font-size: 88px;
          line-height: 90px;
        }

        @include mobile {
          font-size: 55px;
          line-height: 60px;
        }
      }
    }
  }

  &-coord {
    top: 0;

    &__elem {
      &:before {
        content: "02"
      }

      &:after {
        content: "03"
      }
    }
  }

  @include high-desktop {
    padding-bottom: 70px;
  }

  @include mobile {
    padding-bottom: 0;
  }
}
