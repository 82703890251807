.counter {
  padding-left: 23px;
  counter-reset: my-awesome-counter;
  list-style: none;

  &__item {
    counter-increment: my-awesome-counter;
    position: relative;
    max-width: 346px;
    font-size: 16px;
    line-height: 19px;
    color: var(--dark-color);

    &:not(:last-child) {
      margin-bottom: 19px;
    }

    &::before {
      content: ""counter(my-awesome-counter) ".";
      position: absolute;
      left: -25px;
      top: 0;
      font-size: 16px;
      line-height: 19px;
      color: var(--orange-color);
    }
  }
}
