.plan {
  position: relative;
  padding-top: 120px;
  padding-bottom: 80px;
  background: url("../img/plan-bg.png") 35% center / 2009px 720px no-repeat;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-left {
    margin-left: -50px;
    width: 50%;

    @include high-desktop {
      margin-left: 0;
      width: 40%;
    }

    &__img {
      @include small-tablet {
        display: none;
      }
    }

    @include small-tablet {
      width: 0;
    }
  }

  &-right {
    padding-top: 15px;
    width: 50%;

    &__title {
      padding-top: 40px;
      margin-bottom: 36px;
      width: 85%;
    }

    &__descr {
      margin-bottom: 54px;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: var(--alto-color);
    }

    &-form {
      &__input {
        margin-right: 10px;

        @include mobile {
          margin: 0;
          margin-bottom: 30px;
          width: 100%;
        }
      }

      &__btn {
        @include mobile {
          margin: 0;
          width: 100%;
        }
      }

      @include mobile {
        flex-flow: column wrap;
      }
    }

    @include high-desktop {
      width: 57%;
    }

    @include small-tablet {
      width: 100%;
    }
  }

  &-coord {
    top: 7%;

    &__elem {
      &:before {
        content: "06"
      }

      &:after {
        content: "07"
      }
    }
  }

  @include high-desktop {
    background-size: cover;
  }

  @include mobile {
    padding: 200px 0;
    padding-bottom: 100px;
  }
}
