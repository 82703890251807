// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "inter", sans-serif;
  --content-width: 1170px;
  --content-fluid-width: 1820px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --dark-color: #2b2a28;
  --alto-color: #dcd;
  --silver-chalice: #aaa;
  --gray-color: #8b8b8b;
  --turbo-color: #f8df00;
  --orange-color: #f8b100;
  --green-color: #36c12a;
}
