.main-btn {
  border-radius: 8px;
  padding: 18px 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--dark-color);
  background-color: var(--orange-color);

  @include mobile {
    font-size: 22px;
    line-height: 29px;
  }
}
