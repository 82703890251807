.benefits {
  padding-top: 50px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-item {
    position: relative;
    padding-top: 72px;
    width: 31.6%;

    &:before {
      content: "";
      position: absolute;
      right: 108%;
      top: 20%;
      border-bottom: 1px dashed #aaa;
      width: 185px;
    }

    &__count {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      font-weight: 900;
      font-size: 96px;
      line-height: 116px;
      text-transform: uppercase;
      color: var(--turbo-color);
      opacity: 0.6;
    }

    &__title {
      z-index: 100;
      margin: 0;
      margin-bottom: 63px;
      padding: 0;
      font-weight: 900;
      font-size: 24px;
      line-height: 120%;
      text-transform: uppercase;
      color: var(--dark-color);

      @include high-desktop {
        margin-bottom: 30px;
      }
    }

    &__descr {
      margin: 0;
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    @include high-desktop {
      margin-bottom: 30px;
      width: 50%;
    }

    @include small-tablet {
      width: 100%;
    }
  }

  @include high-desktop {
    padding-top: 0;
  }
}
