:root {
  --font-family: "inter", sans-serif;
  --content-width: 1170px;
  --content-fluid-width: 1820px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --dark-color: #2b2a28;
  --alto-color: #dcd;
  --silver-chalice: #aaa;
  --gray-color: #8b8b8b;
  --turbo-color: #f8df00;
  --orange-color: #f8b100;
  --green-color: #36c12a;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "inter";
  src: url("../fonts/../fonts/Inter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "inter";
  src: url("../fonts/../fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "inter";
  src: url("../fonts/../fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "inter";
  src: url("../fonts/../fonts/Inter-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "inter";
  src: url("../fonts/../fonts/Inter-Black.woff2") format("woff2");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "sf";
  src: url("../fonts/../fonts/SFUIDisplay-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "suisse";
  src: url("../fonts/../fonts/SuisseIntl-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "suisse";
  src: url("../fonts/../fonts/SuisseIntl-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "suisse";
  src: url("../fonts/../fonts/SuisseIntl-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.yellow-txt {
  font-weight: 700;
  color: var(--turbo-color);
}

.main-btn {
  border-radius: 8px;
  padding: 18px 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--dark-color);
  background-color: var(--orange-color);
}
@media (max-width: 576px) {
  .main-btn {
    font-size: 22px;
    line-height: 29px;
  }
}

.main-input {
  padding-left: 24px;
  width: 370px;
  height: 65px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--silver-chalice);
  background-color: #161615;
}

.coord {
  position: absolute;
  right: 6.3%;
  z-index: 10;
}
.coord__elem {
  width: 1px;
  height: 57px;
  font-family: "sf", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #c4c4c4;
  background-color: #c4c4c4;
}
.coord__elem::before {
  position: absolute;
  left: 50%;
  top: -40%;
  transform: translateX(-50%);
}
.coord__elem::after {
  position: absolute;
  left: 50%;
  bottom: -40%;
  transform: translateX(-50%);
}
@media (max-width: 576px) {
  .coord {
    display: none;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.slider-btn-wrapper {
  display: flex;
  justify-content: space-between;
  width: 120px;
}

.slider-btn {
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--orange-color);
  border-radius: 100%;
  margin: 0;
  width: 47px;
  height: 47px;
  background-color: var(--light-color);
}
.slider-btn svg {
  width: 19px;
  height: 19px;
  fill: transparent;
  stroke: var(--orange-color);
  stroke-width: 2px;
}
.slider-btn-next {
  transform: rotate(-180deg);
}

.yt {
  color: var(--turbo-color);
}

.section-title {
  margin: 0;
  padding: 0;
  font-weight: 900;
  font-size: 38px;
  line-height: 46px;
  text-transform: uppercase;
  color: var(--dark-color);
}
.section-title--wc {
  color: var(--light-color);
}
@media (max-width: 576px) {
  .section-title {
    font-size: 29px;
    line-height: 35px;
  }
}

.counter {
  padding-left: 23px;
  counter-reset: my-awesome-counter;
  list-style: none;
}
.counter__item {
  counter-increment: my-awesome-counter;
  position: relative;
  max-width: 346px;
  font-size: 16px;
  line-height: 19px;
  color: var(--dark-color);
}
.counter__item:not(:last-child) {
  margin-bottom: 19px;
}
.counter__item::before {
  content: "" counter(my-awesome-counter) ".";
  position: absolute;
  left: -25px;
  top: 0;
  font-size: 16px;
  line-height: 19px;
  color: var(--orange-color);
}

.burger {
  --burger-width: 30px;
  --burger-height: 30px;
  --burger-line-height: 2px;
  position: relative;
  border: none;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: var(--orange-color);
  background-color: transparent;
  cursor: pointer;
  z-index: 100;
  display: none;
}
.burger::before, .burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger::before {
  top: 0;
}
.burger::after {
  top: calc(100% - var(--burger-line-height));
}
.burger__line {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}
.burger--active::before {
  top: 50%;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active::after {
  top: 50%;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active .burger__line {
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 1064px) {
  .burger {
    display: block;
  }
}

.header {
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 58px 0;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-logo__link {
  display: inline-block;
  margin-right: 49px;
}
.header-logo__descr {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--light-color);
}
@media (max-width: 576px) {
  .header-logo__descr {
    display: none;
  }
}
.header-contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-contacts__item {
  padding: 10px 0;
  padding-left: 35px;
  background-size: auto;
  background-position: 0 center;
  background-repeat: no-repeat;
  transition: color 0.1s ease-in-out;
}
.header-contacts__item:first-child {
  margin-right: 32px;
  background-image: url("../img/c-tel.svg");
}
@media (max-width: 1064px) {
  .header-contacts__item:first-child {
    margin-right: 0;
  }
}
.header-contacts__item:last-child {
  background-image: url("../img/c-mail.svg");
}
.header-contacts__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--light-color);
}
.header-contacts__link:hover {
  color: var(--orange-color);
  transition: color 0.1s ease-in-out;
}
@media (max-width: 1064px) {
  .header-contacts {
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: auto;
    z-index: 50;
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: var(--dark-color);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
}
@media (max-width: 768px) {
  .header {
    padding: 30px 0;
  }
}

.menu--active {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.hero {
  position: relative;
  padding-top: 285px;
  padding-bottom: 250px;
  background: url("../img/s-1-bg.png") -100px -83px/cover no-repeat;
}
.hero__container {
  display: flex;
  justify-content: space-between;
}
.hero-left {
  z-index: 30;
  width: 60%;
}
.hero-left__title {
  margin: 0;
  margin-bottom: 23px;
  padding: 0;
  font-weight: 900;
  font-size: 56px;
  line-height: 68px;
  color: var(--light-color);
  text-transform: uppercase;
}
@media (max-width: 576px) {
  .hero-left__title {
    font-size: 38px;
    line-height: 45px;
  }
}
.hero-left__descr {
  margin: 0;
  margin-bottom: 64px;
  padding: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: var(--light-color);
}
@media (max-width: 576px) {
  .hero-left__descr {
    font-size: 16px;
    line-height: 22px;
  }
}
.hero-left__btn {
  margin-bottom: 35px;
}
.hero-left__list {
  display: flex;
}
@media (max-width: 1024px) {
  .hero-left__list {
    flex-wrap: wrap;
  }
}
.hero-left__item {
  position: relative;
  padding-left: 40px;
  padding-top: 100px;
  font-size: 14px;
  line-height: 17px;
  color: var(--turbo-color);
  background-size: 24px 24px;
  background-position: 0 92%;
  background-repeat: no-repeat;
}
.hero-left__item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 66px;
  background: url("../img/grey-ln.svg") 0/cover no-repeat;
}
.hero-left__item:not(:last-child) {
  margin-right: 19.5px;
}
.hero-left__item:nth-child(1) {
  background-image: url("../img/s-1-l-1.svg");
}
.hero-left__item:nth-child(2) {
  background-image: url("../img/s-1-l-2.svg");
}
.hero-left__item:nth-child(3) {
  background-image: url("../img/s-1-l-3.svg");
}
@media (max-width: 1024px) {
  .hero-left__item {
    padding: 20px 0;
    padding-left: 40px;
    width: 100%;
    background-position: left center;
  }
  .hero-left__item:before {
    display: none;
  }
}
@media (max-width: 1024px) {
  .hero-left {
    width: 100%;
  }
}
.hero-right__img {
  position: absolute;
  right: -66px;
  top: 148px;
}
@media (max-width: 1200px) {
  .hero-right__img {
    position: static;
  }
}
@media (max-width: 1200px) {
  .hero-right {
    margin-right: -10%;
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .hero-right {
    display: none;
  }
}
.hero-coord {
  bottom: 9.2%;
}
.hero-coord__elem:before {
  content: "01";
}
.hero-coord__elem:after {
  content: "02";
}
@media (max-width: 1200px) {
  .hero {
    padding-top: 200px;
    padding-bottom: 180px;
  }
}
@media (max-width: 768px) {
  .hero {
    padding-top: 120px;
    padding-bottom: 60px;
    background-position: top;
  }
}

.benefits {
  padding-top: 50px;
}
.benefits__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.benefits-item {
  position: relative;
  padding-top: 72px;
  width: 31.6%;
}
.benefits-item:before {
  content: "";
  position: absolute;
  right: 108%;
  top: 20%;
  border-bottom: 1px dashed #aaa;
  width: 185px;
}
.benefits-item__count {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 900;
  font-size: 96px;
  line-height: 116px;
  text-transform: uppercase;
  color: var(--turbo-color);
  opacity: 0.6;
}
.benefits-item__title {
  z-index: 100;
  margin: 0;
  margin-bottom: 63px;
  padding: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--dark-color);
}
@media (max-width: 1200px) {
  .benefits-item__title {
    margin-bottom: 30px;
  }
}
.benefits-item__descr {
  margin: 0;
  padding: 0;
}
.benefits-item__descr:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  .benefits-item {
    margin-bottom: 30px;
    width: 50%;
  }
}
@media (max-width: 768px) {
  .benefits-item {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .benefits {
    padding-top: 0;
  }
}

.format {
  position: relative;
  padding-top: 280px;
}
.format__container {
  padding: 0;
  transform: translate(-10.3%);
}
@media (max-width: 768px) {
  .format__container {
    transform: translate(0);
  }
}
.format-swiper__btn-wrapper {
  position: absolute;
  left: 0;
  top: -1.15%;
  margin-left: max(15px, (100% - 870px) / 2);
}
@media (max-width: 576px) {
  .format-swiper__btn-wrapper {
    position: static;
    margin-bottom: 20px;
  }
}
.format-slide__item {
  display: flex;
}
@media (max-width: 768px) {
  .format-slide__item {
    flex-flow: column wrap;
  }
}
.format-slide__img {
  display: flex;
  justify-content: end;
  width: 60%;
  transform: translateX(-5.2%);
}
.format-slide__img--1 {
  padding-top: 60px;
}
@media (max-width: 1200px) {
  .format-slide__img {
    width: 50%;
    transform: translateX(0);
  }
}
@media (max-width: 768px) {
  .format-slide__img {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .format-slide__img {
    display: none;
  }
}
.format-slide-content {
  margin-left: 7px;
  width: 40%;
}
.format-slide-content__title {
  margin: 0;
  margin-bottom: 16px;
  padding: 0;
  font-weight: 900;
  font-size: 38px;
  line-height: 46px;
  text-transform: uppercase;
  color: var(--dark-color);
}
@media (max-width: 576px) {
  .format-slide-content__title {
    font-size: 29px;
  }
}
.format-slide-content__count {
  display: block;
  margin-bottom: 64px;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--gray-color);
}
@media (max-width: 768px) {
  .format-slide-content__count {
    margin-bottom: 30px;
  }
}
.format-slide-content__descr-wrapper {
  margin-bottom: 65px;
  width: 100%;
}
@media (max-width: 768px) {
  .format-slide-content__descr-wrapper {
    margin-bottom: 35px;
  }
}
.format-slide-content__descr:not(:last-child) {
  margin-bottom: 20px;
}
.format-slide-content__list {
  width: inherit;
}
.format-slide-content__item {
  font-size: 18px;
  line-height: 22px;
  color: var(--dark-color);
}
.format-slide-content__item--yb {
  position: relative;
}
.format-slide-content__item--yb:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  border-bottom: 1px dashed var(--orange-color);
  width: 165px;
}
.format-slide-content__item:not(:last-child) {
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .format-slide-content {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .format-slide-content {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .format {
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .format {
    padding-bottom: 80px;
  }
}

.results {
  position: relative;
  padding-top: 5px;
  padding-bottom: 119px;
}
.results__content {
  padding-left: max(15px, (100% - 1170px) / 2);
}
.results__title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--gray-color);
}
.results-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 53px;
}
.results-header__left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .results-header__left {
    justify-content: start;
    margin-bottom: 30px;
    width: 100%;
  }
}
.results-header__right {
  display: flex;
  align-items: end;
  justify-content: end;
}
.results-header__right span {
  display: flex;
  justify-content: end;
  max-width: 412px;
  padding-left: 45px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--green-color);
  background: url("../../img/check.svg") 0 center/auto no-repeat;
}
@media (max-width: 768px) {
  .results-header {
    flex-wrap: wrap;
  }
}
.results-swiper__btn-wrapper {
  margin-right: 40px;
}
.results-swiper-slide {
  width: initial;
}
.results-swiper-slide__list {
  display: flex;
  flex-flow: column wrap;
}
.results-swiper-slide__item:not(:last-child) {
  margin-right: 105px;
}
.results-swiper-slide__main-text {
  display: block;
  margin-bottom: 8px;
  font-weight: 900;
  font-size: 206px;
  line-height: 249px;
  text-transform: uppercase;
  color: var(--dark-color);
}
.results-swiper-slide__main-text--yt {
  font-size: 109px;
  line-height: 131.91px;
  color: var(--orange-color);
}
@media (max-width: 768px) {
  .results-swiper-slide__main-text--yt {
    font-size: 60px;
    line-height: 70px;
  }
}
@media (max-width: 576px) {
  .results-swiper-slide__main-text--yt {
    font-size: 40px;
    line-height: 55px;
  }
}
@media (max-width: 1200px) {
  .results-swiper-slide__main-text {
    font-size: 106px;
    line-height: 149px;
  }
}
@media (max-width: 768px) {
  .results-swiper-slide__main-text {
    font-size: 88px;
    line-height: 90px;
  }
}
@media (max-width: 576px) {
  .results-swiper-slide__main-text {
    font-size: 55px;
    line-height: 60px;
  }
}
.results-coord {
  top: 0;
}
.results-coord__elem:before {
  content: "02";
}
.results-coord__elem:after {
  content: "03";
}
@media (max-width: 1200px) {
  .results {
    padding-bottom: 70px;
  }
}
@media (max-width: 576px) {
  .results {
    padding-bottom: 0;
  }
}

.pres {
  padding: 210px 0 150px;
  position: relative;
  background: url("../../img/pres-bg.png") 49% top/cover no-repeat;
}
.pres__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0px;
}
.pres-left {
  margin-top: -10px;
  width: 50%;
}
.pres-left__title {
  margin-bottom: 37px;
}
@media (max-width: 768px) {
  .pres-left__title {
    margin-bottom: 20px;
  }
}
.pres-left-descr {
  margin-bottom: 55px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--alto-color);
}
.pres-left-descr span {
  display: block;
  margin-bottom: 16px;
}
.pres-left-descr-list {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--alto-color);
}
@media (max-width: 768px) {
  .pres-left-descr {
    margin-bottom: 20px;
  }
}
.pres-left-form {
  display: flex;
}
.pres-left-form__input {
  margin-right: 16px;
}
@media (max-width: 576px) {
  .pres-left-form__input {
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .pres-left-form {
    flex-flow: column wrap;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .pres-left {
    width: 100%;
  }
}
.pres-right {
  display: block;
}
.pres-right__img {
  position: absolute;
  top: 12px;
  right: 36px;
}
@media (max-width: 1200px) {
  .pres-right__img {
    position: static;
  }
}
@media (max-width: 1024px) {
  .pres-right {
    display: none;
  }
}
.pres-coord {
  top: 30%;
}
.pres-coord__elem:before {
  content: "03";
}
.pres-coord__elem:after {
  content: "04";
}
@media (max-width: 1200px) {
  .pres {
    padding: 80px 0;
  }
}
@media (max-width: 1024px) {
  .pres {
    padding-top: 200px;
  }
}
@media (max-width: 768px) {
  .pres {
    background-position: center;
  }
}

.earn {
  position: relative;
  padding-top: 167px;
}
.earn__title {
  margin-bottom: 135px;
}
@media (max-width: 1200px) {
  .earn__title {
    margin-bottom: 45px;
  }
}
.earn-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 31px;
  row-gap: 100px;
}
@media (max-width: 1200px) {
  .earn-list {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;
  }
}
@media (max-width: 768px) {
  .earn-list {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 50px;
  }
}
.earn-item {
  position: relative;
}
.earn-item__text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  padding: 8px;
  border-radius: 8px;
  font-weight: 700;
  background: var(--turbo-color);
}
.earn-item__img {
  display: block;
  border-radius: 100%;
}
.earn-coord {
  top: 10.5%;
}
.earn-coord__elem:before {
  content: "04";
}
.earn-coord__elem:after {
  content: "05";
}
@media (max-width: 1200px) {
  .earn {
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  .earn {
    padding-top: 30px;
  }
}

.owner {
  position: relative;
  padding-top: 285px;
  padding-bottom: 300px;
  max-height: 983px;
  background: url("../img/owner.png") -112.1% 1.4%/auto no-repeat;
}
.owner__container {
  display: flex;
  justify-content: end;
}
.owner-content {
  margin-right: 59px;
  width: 45%;
}
.owner-content__title {
  margin-bottom: 64px;
}
.owner-content__descr-wrapper {
  width: 100%;
  margin-bottom: 105px;
}
@media (max-width: 576px) {
  .owner-content__descr-wrapper {
    margin-bottom: 30px;
  }
}
.owner-content__descr:not(:last-child) {
  margin-bottom: 20px;
}
.owner-content-name span {
  display: block;
}
.owner-content-name__main-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--dark-color);
}
.owner-content-name__secondary-text {
  font-size: 14px;
  line-height: 17px;
  color: #d39804;
}
@media (max-width: 768px) {
  .owner-content {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .owner-content {
    margin: 0;
  }
}
.owner-coord {
  top: 26%;
}
.owner-coord__elem:before {
  content: "05";
}
.owner-coord__elem:after {
  content: "06";
}
@media (max-width: 1200px) {
  .owner {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 50px;
    background-size: contain;
    background-position: -80% center;
  }
}
@media (max-width: 1024px) {
  .owner {
    background-size: 630px 600px;
    background-position: -40% bottom;
  }
}
@media (max-width: 768px) {
  .owner {
    padding-bottom: 0;
    height: 1000px;
    background-size: 630px 600px;
    background-position: 150% bottom;
  }
}
@media (max-width: 576px) {
  .owner {
    background-size: 450px 450px;
    background-position: center 110%;
  }
}

.advantage-item {
  position: relative;
  display: flex;
  padding-top: 120px;
  padding-bottom: 10px;
  background-size: auto;
  background-position: left center;
  background-repeat: no-repeat;
  background: url("../img/adv-3.svg") -20% 20%/auto no-repeat;
}
.advantage-item:before {
  content: "";
  position: absolute;
  width: 126px;
  height: 126px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .advantage-item:before {
    width: 86px;
    height: 86px;
  }
}
@media (max-width: 576px) {
  .advantage-item:before {
    display: none;
  }
}
.advantage-item:not(:last-child) {
  margin-bottom: 78px;
}
.advantage-item:nth-child(1) {
  background-image: url("../img/adv-1.svg");
}
.advantage-item:nth-child(1):before {
  left: -13.3%;
  top: 20.3%;
  background-image: url("../img/user-check.svg");
}
@media (max-width: 768px) {
  .advantage-item:nth-child(1):before {
    left: -13%;
    top: center;
  }
}
.advantage-item:nth-child(2) {
  margin-bottom: 47px;
  background-image: url("../img/adv-2.svg");
}
.advantage-item:nth-child(2):before {
  left: -15.1%;
  top: 16.9%;
  background-image: url("../img/file.svg");
}
@media (max-width: 768px) {
  .advantage-item:nth-child(2):before {
    left: -13%;
    top: center;
  }
}
.advantage-item:nth-child(3) {
  background-image: url("../img/adv-3.svg");
}
.advantage-item:nth-child(3):before {
  left: -15.1%;
  top: 15.4%;
  background-image: url("../img/users.svg");
}
@media (max-width: 768px) {
  .advantage-item:nth-child(3):before {
    left: -13%;
    top: center;
  }
}
.advantage-item__count {
  z-index: -1;
  position: absolute;
  left: -18px;
  top: 2px;
  font-weight: 900;
  font-size: 200px;
  line-height: 242px;
  text-align: right;
  text-transform: uppercase;
  color: var(--alto-color);
  opacity: 0.6;
}
@media (max-width: 1200px) {
  .advantage-item__count {
    top: 40px;
    font-size: 100px;
    line-height: 142px;
  }
}
.advantage-item-left {
  width: 55%;
}
.advantage-item-left__title {
  margin: 0;
  margin-bottom: 65px;
  padding: 0;
  font-weight: 900;
  font-size: 38px;
  line-height: 46px;
  text-transform: uppercase;
  color: var(--dark-color);
}
@media (max-width: 1200px) {
  .advantage-item-left__title {
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 36px;
  }
}
.advantage-item-left__descr-wrapper {
  width: 88%;
}
.advantage-item-left__descr {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--dark-color);
}
.advantage-item-left__descr:not(:last-child) {
  margin-bottom: 22px;
}
@media (max-width: 1200px) {
  .advantage-item-left__descr {
    font-size: 14px;
    line-height: 16px;
  }
}
@media (max-width: 768px) {
  .advantage-item-left {
    width: 100%;
    margin-bottom: 20px;
  }
}
.advantage-item-right {
  width: 45%;
}
@media (max-width: 768px) {
  .advantage-item-right {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .advantage-item-right__list {
    display: flex;
    flex-flow: column wrap;
  }
}
.advantage-item-right__elem {
  position: relative;
  padding-left: 220px;
  padding-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--dark-color);
  background-size: auto;
  background-position: 32% top;
  background-repeat: no-repeat;
}
.advantage-item-right__elem:not(:last-child) {
  margin-bottom: 52px;
}
.advantage-item-right__elem:nth-child(1) {
  background-image: url("../img/adv-1-elem-1.svg");
}
.advantage-item-right__elem:nth-child(2) {
  background-image: url("../img/adv-1-elem-2.svg");
}
.advantage-item-right__elem:nth-child(3) {
  background-image: url("../img/adv-1-elem-3.svg");
}
.advantage-item-right__elem:before {
  content: "";
  position: absolute;
  left: 0;
  top: 35%;
  border-bottom: 1px dashed #aaa;
  width: 117px;
}
@media (max-width: 768px) {
  .advantage-item-right__elem:before {
    display: none;
  }
}
@media (max-width: 1200px) {
  .advantage-item-right__elem {
    padding-left: 200px;
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-color);
    background-size: auto;
    background-position: 35% top;
  }
  .advantage-item-right__elem:not(:last-child) {
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .advantage-item-right__elem {
    padding: 20px 0;
    padding-left: 50px;
    background-position: left center;
  }
  .advantage-item-right__elem:not(:last-child) {
    margin-bottom: 15px;
  }
}
@media (max-width: 1200px) {
  .advantage-item:not(:last-child) {
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .advantage-item {
    flex-wrap: wrap;
    margin-left: 50px;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .advantage-item {
    margin-left: 10px;
  }
}

.advantage-item--2 {
  display: flex;
  flex-wrap: wrap;
}
.advantage-item--2 .advantage-item-left {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.advantage-item--2 .advantage-item-left__title {
  width: 50%;
}
@media (max-width: 768px) {
  .advantage-item--2 .advantage-item-left__title {
    width: 100%;
  }
}
.advantage-item--2 .advantage-item-left__descr-wrapper {
  width: 50%;
}
@media (max-width: 768px) {
  .advantage-item--2 .advantage-item-left__descr-wrapper {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .advantage-item--2 .advantage-item-left {
    display: flex;
    flex-wrap: wrap;
  }
}
.advantage-item--2 .advantage-item-right {
  width: 100%;
}
.advantage-item--2 .advantage-item-right__list {
  display: flex;
}
@media (max-width: 1200px) {
  .advantage-item--2 .advantage-item-right__list {
    padding-top: 20px;
  }
}
@media (max-width: 768px) {
  .advantage-item--2 .advantage-item-right__list {
    padding-top: 0;
  }
}
.advantage-item--2 .advantage-item-right__elem {
  padding: 0;
  padding-top: 213px;
  width: 19%;
  background-position: left center;
}
.advantage-item--2 .advantage-item-right__elem:not(:last-child) {
  margin-right: 15.2%;
}
.advantage-item--2 .advantage-item-right__elem:nth-child(1) {
  background-image: url("../img/adv-2-elem-1.svg");
}
@media (max-width: 1200px) {
  .advantage-item--2 .advantage-item-right__elem:nth-child(1) {
    background-position: left center;
  }
}
@media (max-width: 768px) {
  .advantage-item--2 .advantage-item-right__elem:nth-child(1) {
    background-position: left center;
    background-size: 38px 38px;
  }
}
.advantage-item--2 .advantage-item-right__elem:nth-child(2) {
  background-position: left 52%;
  background-image: url("../img/adv-2-elem-2.svg");
}
@media (max-width: 1200px) {
  .advantage-item--2 .advantage-item-right__elem:nth-child(2) {
    background-position: left center;
  }
}
@media (max-width: 768px) {
  .advantage-item--2 .advantage-item-right__elem:nth-child(2) {
    background-position: left center;
    background-size: 38px 38px;
  }
}
.advantage-item--2 .advantage-item-right__elem:nth-child(3) {
  background-position: left 40%;
  background-image: url("../img/adv-2-elem-3.svg");
}
@media (max-width: 1200px) {
  .advantage-item--2 .advantage-item-right__elem:nth-child(3) {
    background-position: left center;
  }
}
@media (max-width: 768px) {
  .advantage-item--2 .advantage-item-right__elem:nth-child(3) {
    background-position: left center;
    background-size: 38px 38px;
  }
}
.advantage-item--2 .advantage-item-right__elem:before {
  content: "";
  position: absolute;
  left: -52%;
  right: 0;
  top: -12px;
  border: none;
  border-right: 1px dashed #aaa;
  height: 65px;
}
@media (max-width: 1200px) {
  .advantage-item--2 .advantage-item-right__elem {
    padding-top: 150px;
    width: 30%;
  }
}
@media (max-width: 768px) {
  .advantage-item--2 .advantage-item-right__elem {
    padding: 20px 0;
    padding-left: 50px;
    width: 100%;
  }
}

.advantage-item--3 .advantage-item-left {
  width: 53%;
}
@media (max-width: 768px) {
  .advantage-item--3 .advantage-item-left {
    width: 100%;
  }
}
.advantage-item--3 .advantage-item-left__title {
  width: 80%;
}
.advantage-item--3 .advantage-item-right {
  width: 47%;
  margin-top: -36px;
}
@media (max-width: 768px) {
  .advantage-item--3 .advantage-item-right__list {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
  }
}
.advantage-item--3 .advantage-item-right__elem {
  padding-left: 292px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-position: 36.6% center;
}
.advantage-item--3 .advantage-item-right__elem:not(:last-child) {
  margin-bottom: 47.5px;
}
@media (max-width: 1200px) {
  .advantage-item--3 .advantage-item-right__elem:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .advantage-item--3 .advantage-item-right__elem:not(:last-child) {
    margin-bottom: 15px;
  }
}
.advantage-item--3 .advantage-item-right__elem:nth-child(1) {
  background-image: url("../img/adv-3-elem-1.svg");
}
.advantage-item--3 .advantage-item-right__elem:nth-child(2) {
  background-image: url("../img/adv-3-elem-2.svg");
}
.advantage-item--3 .advantage-item-right__elem:nth-child(3) {
  background-image: url("../img/adv-3-elem-3.svg");
}
.advantage-item--3 .advantage-item-right__elem:nth-child(4) {
  background-image: url("../img/adv-3-elem-4.svg");
}
.advantage-item--3 .advantage-item-right__elem:before {
  top: 50%;
  width: 149px;
}
@media (max-width: 1200px) {
  .advantage-item--3 .advantage-item-right__elem {
    padding-left: 230px;
    background-position: 39.5% center;
  }
}
@media (max-width: 768px) {
  .advantage-item--3 .advantage-item-right__elem {
    padding: 20px 0;
    padding-left: 50px;
    background-position: left center;
    background-size: 38px 38px;
  }
}
@media (max-width: 768px) {
  .advantage-item--3 .advantage-item-right {
    width: 100%;
    margin-top: 0;
  }
}

.plan {
  position: relative;
  padding-top: 120px;
  padding-bottom: 80px;
  background: url("../img/plan-bg.png") 35% center/2009px 720px no-repeat;
}
.plan__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.plan-left {
  margin-left: -50px;
  width: 50%;
}
@media (max-width: 1200px) {
  .plan-left {
    margin-left: 0;
    width: 40%;
  }
}
@media (max-width: 768px) {
  .plan-left__img {
    display: none;
  }
}
@media (max-width: 768px) {
  .plan-left {
    width: 0;
  }
}
.plan-right {
  padding-top: 15px;
  width: 50%;
}
.plan-right__title {
  padding-top: 40px;
  margin-bottom: 36px;
  width: 85%;
}
.plan-right__descr {
  margin-bottom: 54px;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--alto-color);
}
.plan-right-form__input {
  margin-right: 10px;
}
@media (max-width: 576px) {
  .plan-right-form__input {
    margin: 0;
    margin-bottom: 30px;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .plan-right-form__btn {
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .plan-right-form {
    flex-flow: column wrap;
  }
}
@media (max-width: 1200px) {
  .plan-right {
    width: 57%;
  }
}
@media (max-width: 768px) {
  .plan-right {
    width: 100%;
  }
}
.plan-coord {
  top: 7%;
}
.plan-coord__elem:before {
  content: "06";
}
.plan-coord__elem:after {
  content: "07";
}
@media (max-width: 1200px) {
  .plan {
    background-size: cover;
  }
}
@media (max-width: 576px) {
  .plan {
    padding: 200px 0;
    padding-bottom: 100px;
  }
}

.packet {
  position: relative;
  padding-top: 189px;
}
.packet__img {
  position: absolute;
  right: 0;
  top: 3.4%;
  width: 860px;
}
@media (max-width: 1200px) {
  .packet__img {
    top: center;
    width: 400px;
  }
}
@media (max-width: 576px) {
  .packet__img {
    display: none;
  }
}
.packet__title {
  margin-bottom: 108px;
}
@media (max-width: 576px) {
  .packet__title {
    margin-bottom: 30px;
  }
}
.packet__list {
  display: flex;
  flex-wrap: wrap;
  width: 780px;
}
@media (max-width: 576px) {
  .packet__list {
    width: 100%;
  }
}
.packet-item {
  z-index: 10000000;
  position: relative;
  margin-right: 16px;
  margin-bottom: 45px;
  width: 370px;
}
.packet-item__count {
  z-index: -1;
  position: absolute;
  left: -36.4%;
  top: 3%;
  font-weight: 900;
  font-size: 96px;
  line-height: 116px;
  text-align: right;
  text-transform: uppercase;
  color: var(--alto-color);
  opacity: 0.6;
}
@media (max-width: 1200px) {
  .packet-item__count {
    left: 0;
    top: 0;
  }
}
.packet-item:nth-child(1) {
  margin-right: 50px;
}
@media (max-width: 1200px) {
  .packet-item:nth-child(1) {
    margin-right: 0;
  }
}
.packet-item:nth-child(2) {
  display: block;
}
@media (max-width: 1200px) {
  .packet-item:nth-child(2) {
    margin-right: 0;
  }
}
.packet-item:nth-child(3) {
  display: block;
}
@media (max-width: 1200px) {
  .packet-item:nth-child(3) {
    margin-right: 0;
  }
}
.packet-item__title {
  margin-bottom: 36px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--dark-color);
}
@media (max-width: 1200px) {
  .packet-item {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .packet-item {
    width: 100%;
  }
}
.packet-coord {
  top: 14%;
}
.packet-coord__elem:before {
  content: "07";
}
.packet-coord__elem:after {
  content: "08";
}
@media (max-width: 1200px) {
  .packet {
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .packet {
    padding-top: 50px;
  }
}

.invest {
  position: relative;
  padding-top: 165px;
}
.invest__title {
  margin-bottom: 130px;
}
@media (max-width: 1200px) {
  .invest__title {
    margin-bottom: 60px;
  }
}
.invest-list {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .invest-list {
    flex-wrap: wrap;
  }
}
.invest-list-item {
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  padding-left: 9%;
  padding-top: 40px;
  padding-bottom: 45px;
  width: 48.6%;
  border: 1px dashed var(--silver-chalice);
  box-sizing: border-box;
  border-radius: 8px;
}
.invest-list-item__title {
  display: flex;
  margin: 0;
  margin-bottom: 60px;
  padding: 20px 0;
  padding-left: 80px;
  background-position: left center;
  background-size: auto;
  background-repeat: no-repeat;
}
.invest-list-item__title--1 {
  background-image: url("../img/invest-1.svg");
}
.invest-list-item__title--2 {
  background-image: url("../img/invest-2.svg");
}
.invest-list-item__title span {
  display: block;
  padding: 8px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--dark-color);
  background-color: var(--turbo-color);
}
@media (max-width: 576px) {
  .invest-list-item__title {
    padding-left: 0;
    padding-top: 100px;
    background-position: center top;
  }
}
@media (max-width: 576px) {
  .invest-list-item__parms {
    width: initial;
    font-size: 12px;
    text-align: center;
  }
}
.invest-list-item__elem {
  font-size: 18px;
  line-height: 22px;
  color: var(--dark-color);
}
.invest-list-item__elem:not(:last-child) {
  margin-bottom: 8px;
}
@media (max-width: 1200px) {
  .invest-list-item {
    width: 100%;
  }
  .invest-list-item:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .invest-list-item {
    flex-flow: column wrap;
    align-items: center;
    padding: 20px 0;
  }
}
.invest-coord {
  top: 20%;
}
.invest-coord__elem:before {
  content: "08";
}
.invest-coord__elem:after {
  content: "09";
}
@media (max-width: 1200px) {
  .invest-coord {
    top: 0;
  }
}
@media (max-width: 1200px) {
  .invest {
    padding-top: 50px;
  }
}

.city {
  position: relative;
  padding-top: 410px;
  padding-bottom: 30px;
  background: url("../img/city-bg.png") center -14.65%/auto no-repeat;
}
.city__container {
  display: flex;
  align-items: center;
}
.city-left {
  width: 50%;
}
.city-left__title {
  margin-bottom: 35px;
}
.city-left__descr {
  margin-bottom: 54px;
  font-size: 16px;
  line-height: 19px;
  color: var(--light-color);
}
.city-left-form {
  display: flex;
}
.city-left-form__input {
  margin-right: 16px;
  width: 370px;
}
@media (max-width: 576px) {
  .city-left-form__input {
    margin-bottom: 20px;
    width: 100%;
  }
}
.city-left-form__btn {
  padding: 18px 58px;
}
@media (max-width: 576px) {
  .city-left-form__btn {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .city-left-form {
    flex-flow: column wrap;
  }
}
@media (max-width: 1200px) {
  .city-left {
    width: 70%;
  }
}
@media (max-width: 1024px) {
  .city-left {
    width: 100%;
  }
}
.city-right__img {
  position: absolute;
  top: 26%;
  right: 0;
  display: block;
  width: 645px;
}
@media (max-width: 1200px) {
  .city-right__img {
    top: 50%;
    width: 200px;
  }
}
@media (max-width: 1024px) {
  .city-right__img {
    display: none;
  }
}
@media (max-width: 1200px) {
  .city-right {
    width: 30%;
  }
}
@media (max-width: 1200px) {
  .city {
    padding-top: 300px;
  }
}

.footer {
  padding-top: 178px;
  padding-bottom: 60px;
  background-color: #161615;
}
.footer__container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .footer__container {
    flex-flow: column wrap;
  }
}
.footer-logo {
  display: flex;
  flex-wrap: wrap;
}
.footer-logo__img {
  margin-right: 94px;
}
@media (max-width: 576px) {
  .footer-logo__img {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.footer-logo__descr {
  width: 40%;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: var(--light-color);
  opacity: 0.4;
}
@media (max-width: 576px) {
  .footer-logo__descr {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .footer-logo {
    margin-bottom: 30px;
  }
}
@media (max-width: 1200px) {
  .footer {
    padding-top: 100px;
  }
}

.franch {
  margin-right: 39px;
  font-family: "suisse", serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  color: var(--light-color);
  transition: color 0.1s ease-in-out;
}
.franch:hover {
  color: var(--orange-color);
  transition: color 0.1s ease-in-out;
}
.franch__img {
  margin-bottom: 15px;
}
.franch__txt {
  display: block;
}

.bg-1 {
  background: url("../img/bg-1.png") center/auto no-repeat;
}

.bg-2 {
  background: url("../img/bg-2.png") center/auto no-repeat, url("../img/bg-3.png") center bottom/auto no-repeat;
}

.bg-3 {
  background: url("../img/bg-4.png") center top/auto no-repeat, url("../img/bg-5.png") center bottom/auto no-repeat;
}

.graph-modal__container {
  padding: 0;
  max-width: 450px;
  border: 1px solid #F2C317;
  box-sizing: border-box;
  box-shadow: 0px 100px 203px rgba(0, 0, 0, 0.03), 0px 36.5016px 74.0983px rgba(0, 0, 0, 0.0206994), 0px 17.7209px 35.9734px rgba(0, 0, 0, 0.0166887), 0px 8.6871px 17.6348px rgba(0, 0, 0, 0.0133113), 0px 3.43489px 6.97283px rgba(0, 0, 0, 0.00930055);
  border-radius: 32px;
}

.graph-modal__close {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 42px;
  right: 42px;
  width: 12px;
  height: 12px;
  background-image: url("../img/close.svg");
}

.popup {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0;
  padding: 96px 0 74px;
}
.popup__title {
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  max-width: 370px;
  font-weight: 900;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  color: var(--dark-color);
}
.popup__descr {
  margin: 0;
  margin-bottom: 27px;
  padding: 0;
  max-width: 300px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #858585;
}

.form {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}
.form__label {
  display: flex;
  flex-direction: column-reverse;
}
.form__label:not(:last-child) {
  margin-bottom: 12px;
}
.form__input {
  display: block;
  border: 1px solid #0083d0;
  box-sizing: border-box;
  box-shadow: 0px 100px 203px rgba(0, 0, 0, 0.03), 0px 36.5016px 74.0983px rgba(0, 0, 0, 0.0206994), 0px 17.7209px 35.9734px rgba(0, 0, 0, 0.0166887), 0px 8.6871px 17.6348px rgba(0, 0, 0, 0.0133113), 0px 3.43489px 6.97283px rgba(0, 0, 0, 0.00930055);
  border-radius: 8px;
  width: 299px;
  height: 65px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #858585;
  text-align: center;
  background: var(--light-color);
}
.form__btn {
  display: block;
  width: 100%;
}