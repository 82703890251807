.bg-1 {
  background: url("../img/bg-1.png") center / auto no-repeat;
}

.bg-2 {
  background: url("../img/bg-2.png") center / auto no-repeat, url("../img/bg-3.png") center bottom / auto no-repeat;
}

.bg-3 {
  background: url("../img/bg-4.png") center top / auto no-repeat, url("../img/bg-5.png") center bottom / auto no-repeat;
}
