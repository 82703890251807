.earn {
  position: relative;
  padding-top: 167px;

  &__title {
    margin-bottom: 135px;

    @include high-desktop {
      margin-bottom: 45px;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 31px;
    row-gap: 100px;

    @include high-desktop {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
    }

    @include small-tablet {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 50px;
    }
  }

  &-item {
    position: relative;

    &__text {
      @include flex-all-center;

      position: absolute;
      left: 0;
      top: 0;
      padding: 8px;
      border-radius: 8px;
      font-weight: 700;
      background: var(--turbo-color);
    }

    &__img {
      display: block;
      border-radius: 100%;
    }
  }

  &-coord {
    top: 10.5%;

    &__elem {
      &:before {
        content: "04"
      }

      &:after {
        content: "05"
      }
    }
  }

  @include high-desktop {
    padding-top: 80px;
  }

  @include small-tablet {
    padding-top: 30px;
  }
}
