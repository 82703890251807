.pres {
  padding: 210px 0 150px;
  position: relative;
  background: url("../../img/pres-bg.png") 49% top / cover no-repeat;

  &__container {
    @include flex-all-sb;

    padding-top: 0px;
  }

  &-left {
    margin-top: -10px;
    width: 50%;

    &__title {
      margin-bottom: 37px;

      @include small-tablet {
        margin-bottom: 20px;
      }
    }

    &-descr {
      margin-bottom: 55px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--alto-color);

      span {
        display: block;
        margin-bottom: 16px;
      }

      &-list {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--alto-color);
      }

      @include small-tablet {
        margin-bottom: 20px;
      }
    }

    &-form {
      display: flex;

      &__input {
        margin-right: 16px;

        @include mobile {
          margin: 0;
          margin-bottom: 20px;
          width: 100%;
        }
      }

      @include mobile {
        flex-flow: column wrap;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }

    @include tablet {
      width: 100%;
    }
  }

  &-right {
    display: block;

    &__img {
      position: absolute;
      top: 12px;
      right: 36px;

      @include high-desktop {
        position: static;
      }
    }

    @include tablet {
      display: none;
    }
  }

  &-coord {
    top: 30%;

    &__elem {
      &:before {
        content: "03"
      }

      &:after {
        content: "04"
      }
    }
  }

  @include high-desktop {
    padding: 80px 0;
  }

  @include tablet {
    padding-top: 200px;
  }

  @include small-tablet {
    background-position: center;
  }
}
