.advantage {
  &-item {
    position: relative;
    display: flex;
    padding-top: 120px;
    padding-bottom: 10px;
    background-size: auto;
    background-position: left center;
    background-repeat: no-repeat;
    background: url("../img/adv-3.svg") -20% 20% / auto no-repeat;

    &:before {
      content: "";
      position: absolute;
      width: 126px;
      height: 126px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @include small-tablet {
        width: 86px;
        height: 86px;
      }

      @include mobile {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: 78px;
    }

    &:nth-child(1) {
      background-image: url("../img/adv-1.svg");

      &:before {
        left: -13.3%;
        top: 20.3%;
        background-image: url("../img/user-check.svg");

        @include small-tablet {
          left: -13%;
          top: center;
        }
      }
    }

    &:nth-child(2) {
      margin-bottom: 47px;
      background-image: url("../img/adv-2.svg");

      &:before {
        left: -15.1%;
        top: 16.9%;
        background-image: url("../img/file.svg");

        @include small-tablet {
          left: -13%;
          top: center;
        }
      }
    }

    &:nth-child(3) {
      background-image: url("../img/adv-3.svg");

      &:before {
        left: -15.1%;
        top: 15.4%;
        background-image: url("../img/users.svg");

        @include small-tablet {
          left: -13%;
          top: center;
        }
      }
    }

    &__count {
      z-index: -1;
      position: absolute;
      left: -18px;
      top: 2px;
      font-weight: 900;
      font-size: 200px;
      line-height: 242px;
      text-align: right;
      text-transform: uppercase;
      color: var(--alto-color);
      opacity: 0.6;

      @include high-desktop {
        top: 40px;
        font-size: 100px;
        line-height: 142px;
      }
    }

    &-left {
      width: 55%;

      &__title {
        margin: 0;
        margin-bottom: 65px;
        padding: 0;
        font-weight: 900;
        font-size: 38px;
        line-height: 46px;
        text-transform: uppercase;
        color: var(--dark-color);

        @include high-desktop {
          margin-bottom: 25px;
          font-size: 28px;
          line-height: 36px;
        }
      }

      &__descr-wrapper {
        width: 88%;
      }

      &__descr {
        margin: 0;
        padding: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: var(--dark-color);

        &:not(:last-child) {
          margin-bottom: 22px;
        }

        @include high-desktop {
          font-size: 14px;
          line-height: 16px;
        }
      }

      @include small-tablet {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &-right {
      width: 45%;

      @include small-tablet {
        width: 100%;
      }

      &__list {
        @include small-tablet {
          display: flex;
          flex-flow: column wrap;
        }
      }

      &__elem {
        position: relative;
        padding-left: 220px;
        padding-bottom: 20px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: var(--dark-color);
        background-size: auto;
        background-position: 32% top;
        background-repeat: no-repeat;

        &:not(:last-child) {
          margin-bottom: 52px;
        }

        &:nth-child(1) {
          background-image: url("../img/adv-1-elem-1.svg");
        }

        &:nth-child(2) {
          background-image: url("../img/adv-1-elem-2.svg");
        }

        &:nth-child(3) {
          background-image: url("../img/adv-1-elem-3.svg");
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 35%;
          border-bottom: 1px dashed #aaa;
          width: 117px;

          @include small-tablet {
            display: none;
          }
        }

        @include high-desktop {
          padding-left: 200px;
          padding-bottom: 20px;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: var(--dark-color);
          background-size: auto;
          background-position: 35% top;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }

        @include small-tablet {
          padding: 20px 0;
          padding-left: 50px;
          background-position: left center;

          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }
      }
    }


    @include high-desktop {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    @include small-tablet {
      flex-wrap: wrap;
      margin-left: 50px;
      width: 100%;
    }

    @include mobile {
      margin-left: 10px;
    }
  }
}
