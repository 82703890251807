// сброс стандартных стилей swiper
.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.slider-btn-wrapper {
  display: flex;
  justify-content: space-between;
  width: 120px;
}

.slider-btn {
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--orange-color);
  border-radius: 100%;
  margin: 0;
  width: 47px;
  height: 47px;
  background-color: var(--light-color);

  svg {
    width: 19px;
    height: 19px;
    fill: transparent;
    stroke: var(--orange-color);
    stroke-width: 2px;
  }

  &-next {
    transform: rotate(-180deg);
  }
}
