.invest {
  position: relative;
  padding-top: 165px;

  &__title {
    margin-bottom: 130px;

    @include high-desktop {
      margin-bottom: 60px;
    }
  }

  &-list {
    display: flex;
    justify-content: space-between;

    @include high-desktop {
      flex-wrap: wrap;
    }

    &-item {
      display: flex;
      flex-flow: column wrap;
      align-items: start;
      padding-left: 9%;
      padding-top: 40px;
      padding-bottom: 45px;
      width: 48.6%;
      border: 1px dashed var(--silver-chalice);
      box-sizing: border-box;
      border-radius: 8px;

      &__title {
        display: flex;
        margin: 0;
        margin-bottom: 60px;
        padding: 20px 0;
        padding-left: 80px;
        background-position: left center;
        background-size: auto;
        background-repeat: no-repeat;

        &--1 {
          background-image: url("../img/invest-1.svg");
        }

        &--2 {
          background-image: url("../img/invest-2.svg");
        }

        span {
          display: block;
          padding: 8px;
          border-radius: 8px;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          color: var(--dark-color);
          background-color: var(--turbo-color);
        }

        @include mobile {
          padding-left: 0;
          padding-top: 100px;
          background-position: center top;
        }
      }

      &__parms {
        @include mobile {
          width: initial;
          font-size: 12px;
          text-align: center;
        }
      }

      &__elem {
        font-size: 18px;
        line-height: 22px;
        color: var(--dark-color);

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      @include high-desktop {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      @include mobile {
        flex-flow: column wrap;
        align-items: center;
        padding: 20px 0;
      }
    }
  }

  &-coord {
    top: 20%;

    &__elem {
      &:before {
        content: "08"
      }

      &:after {
        content: "09"
      }
    }

    @include high-desktop {
      top: 0;
    }
  }

  @include high-desktop {
    padding-top: 50px;
  }
}
