.main-input {
  padding-left: 24px;
  width: 370px;
  height: 65px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--silver-chalice);
  background-color: #161615;

  // @include small-tablet {
  //   width: 270px;
  //   height: 36px;
  //   font-size: 12px;
  //   line-height: 16px;
  // }
}
