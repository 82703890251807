.format {
  position: relative;
  padding-top: 280px;

  &__container {
    padding: 0;
    transform: translate(-10.3%);

    @include small-tablet {
      transform: translate(0);
    }
  }

  &-swiper__btn-wrapper {
    position: absolute;
    left: 0;
    top: -1.15%;
    margin-left: max(15px, calc((100% - 870px) / 2));

    @include mobile {
      position: static;
      margin-bottom: 20px;
    }
  }

  &-slide {
    &__item {
      display: flex;

      @include small-tablet {
        flex-flow: column wrap;
      }
    }

    &__img {
      display: flex;
      justify-content: end;
      width: 60%;
      transform: translateX(-5.2%);

      &--1 {
        padding-top: 60px;
      }

      @include high-desktop {
        width: 50%;
        transform: translateX(0);
      }

      @include small-tablet {
        width: 50%;
      }

      @include mobile {
        display: none;
      }
    }

    &-content {
      margin-left: 7px;
      width: 40%;

      &__title {
        margin: 0;
        margin-bottom: 16px;
        padding: 0;
        font-weight: 900;
        font-size: 38px;
        line-height: 46px;
        text-transform: uppercase;
        color: var(--dark-color);

        @include mobile {
          font-size: 29px;
        }
      }

      &__count {
        display: block;
        margin-bottom: 64px;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: var(--gray-color);

        @include small-tablet {
          margin-bottom: 30px;
        }
      }

      &__descr-wrapper {
        margin-bottom: 65px;
        width: 100%;

        @include small-tablet {
          margin-bottom: 35px;
        }
      }

      &__descr {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      &__list {
        width: inherit;
      }

      &__item {
        font-size: 18px;
        line-height: 22px;
        color: var(--dark-color);

        &--yb {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -5px;
            border-bottom: 1px dashed var(--orange-color);
            width: 165px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      @include high-desktop {
        width: 50%;
      }

      @include small-tablet {
        width: 100%;
      }
    }
  }

  @include high-desktop {
    padding-top: 100px;
  }

  @include small-tablet {
    padding-bottom: 80px;
  }
}
