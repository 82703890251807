.city {
  position: relative;
  padding-top: 410px;
  padding-bottom: 30px;
  background: url("../img/city-bg.png") center -14.65% / auto no-repeat;

  &__container {
    display: flex;
    align-items: center;
  }

  &-left {
    width: 50%;

    &__title {
      margin-bottom: 35px;
    }

    &__descr {
      margin-bottom: 54px;
      font-size: 16px;
      line-height: 19px;
      color: var(--light-color);
    }

    &-form {
      display: flex;

      &__input {
        margin-right: 16px;
        width: 370px;

        @include mobile {
          margin-bottom: 20px;
          width: 100%;
        }
      }

      &__btn {
        padding: 18px 58px;

        @include mobile {
          width: 100%;
        }
      }

      @include mobile {
        flex-flow: column wrap;
      }
    }

    @include high-desktop {
      width: 70%;
    }

    @include tablet {
      width: 100%;
    }
  }

  &-right {
    &__img {
      position: absolute;
      top: 26%;
      right: 0;
      display: block;
      width: 645px;

      @include high-desktop {
        top: 50%;
        width: 200px;
      }

      @include tablet {
        display: none;
      }
    }

    @include high-desktop {
      width: 30%;
    }
  }

  @include high-desktop {
    padding-top: 300px;
  }
}
