.hero {
  position: relative;
  padding-top: 285px;
  padding-bottom: 250px;
  background: url("../img/s-1-bg.png") -100px -83px / cover no-repeat;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &-left {
    z-index: 30;
    width: 60%;

    &__title {
      margin: 0;
      margin-bottom: 23px;
      padding: 0;
      font-weight: 900;
      font-size: 56px;
      line-height: 68px;
      color: var(--light-color);
      text-transform: uppercase;

      @include mobile {
        font-size: 38px;
        line-height: 45px;
      }
    }

    &__descr {
      margin: 0;
      margin-bottom: 64px;
      padding: 0;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: var(--light-color);

      @include mobile {
        font-size: 16px;
        line-height: 22px;
      }
    }

    &__btn {
      margin-bottom: 35px;
    }

    &__list {
      display: flex;

      @include tablet {
        flex-wrap: wrap;
      }
    }

    &__item {
      position: relative;
      padding-left: 40px;
      padding-top: 100px;
      font-size: 14px;
      line-height: 17px;
      color: var(--turbo-color);
      background-size: 24px 24px;
      background-position: 0 92%;
      background-repeat: no-repeat;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 66px;
        background: url("../img/grey-ln.svg") 0 / cover no-repeat;
      }

      &:not(:last-child) {
        margin-right: 19.5px;
      }

      &:nth-child(1) {
        background-image: url("../img/s-1-l-1.svg");
      }

      &:nth-child(2) {
        background-image: url("../img/s-1-l-2.svg");
      }

      &:nth-child(3) {
        background-image: url("../img/s-1-l-3.svg");
      }

      @include tablet {
        padding: 20px 0;
        padding-left: 40px;
        width: 100%;
        background-position: left center;

        &:before {
          display: none;
        }
      }
    }

    @include tablet {
      width: 100%;
    }
  }

  &-right {
    &__img {

      position: absolute;
      right: -66px;
      top: 148px;

      @include high-desktop {
        position: static;
      }
    }

    @include high-desktop {
      margin-right: -10%;
      width: 30%;
    }

    @include tablet {
      display: none;
    }
  }

  &-coord {
    bottom: 9.2%;

    &__elem {
      &:before {
        content: "01"
      }

      &:after {
        content: "02"
      }
    }
  }

  @include high-desktop {
    padding-top: 200px;
    padding-bottom: 180px;
  }

  @include small-tablet {
    padding-top: 120px;
    padding-bottom: 60px;
    background-position: top;
  }
}
