.form {
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  &__label {
    display: flex;
    flex-direction: column-reverse;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__input {
    display: block;
    border: 1px solid #0083d0;
    box-sizing: border-box;
    box-shadow: 0px 100px 203px rgba(0, 0, 0, 0.03), 0px 36.5016px 74.0983px rgba(0, 0, 0, 0.0206994), 0px 17.7209px 35.9734px rgba(0, 0, 0, 0.0166887), 0px 8.6871px 17.6348px rgba(0, 0, 0, 0.0133113), 0px 3.43489px 6.97283px rgba(0, 0, 0, 0.00930055);
    border-radius: 8px;
    width: 299px;
    height: 65px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #858585;
    text-align: center;
    background: var(--light-color);
  }

  &__btn {
    display: block;
    width: 100%;
  }
}
