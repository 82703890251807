.advantage-item--2 {
  display: flex;
  flex-wrap: wrap;

  .advantage-item-left {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__title {
      width: 50%;

      @include small-tablet {
        width: 100%;
      }
    }

    &__descr-wrapper {
      width: 50%;

      @include small-tablet {
        width: 100%;
      }
    }

    @include small-tablet {
      display: flex;
      flex-wrap: wrap;
    }

  }

  .advantage-item-right {
    width: 100%;

    &__list {
      display: flex;

      @include high-desktop {
        padding-top: 20px;
      }

      @include small-tablet {
        padding-top: 0;
      }
    }

    &__elem {
      padding: 0;
      padding-top: 213px;
      width: 19%;
      background-position: left center;

      &:not(:last-child) {
        margin-right: 15.2%;
      }

      &:nth-child(1) {
        background-image: url("../img/adv-2-elem-1.svg");

        @include high-desktop {
          background-position: left center;
        }

        @include small-tablet {
          background-position: left center;
          background-size: 38px 38px;
        }
      }

      &:nth-child(2) {
        background-position: left 52%;
        background-image: url("../img/adv-2-elem-2.svg");

        @include high-desktop {
          background-position: left center;
        }

        @include small-tablet {
          background-position: left center;
          background-size: 38px 38px;
        }
      }

      &:nth-child(3) {
        background-position: left 40%;
        background-image: url("../img/adv-2-elem-3.svg");

        @include high-desktop {
          background-position: left center;
        }

        @include small-tablet {
          background-position: left center;
          background-size: 38px 38px;
        }
      }

      &:before {
        content: "";
        position: absolute;
        left: -52%;
        right: 0;
        top: -12px;
        border: none;
        border-right: 1px dashed #aaa;
        height: 65px;
      }

      @include high-desktop {
        padding-top: 150px;
        width: 30%;
      }

      @include small-tablet {
        padding: 20px 0;
        padding-left: 50px;
        width: 100%;
      }
    }
  }
}
