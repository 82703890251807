.section-title {
  margin: 0;
  padding: 0;
  font-weight: 900;
  font-size: 38px;
  line-height: 46px;
  text-transform: uppercase;
  color: var(--dark-color);

  &--wc {
    color: var(--light-color);
  }

  @include mobile {
    font-size: 29px;
    line-height: 35px;
  }
}
