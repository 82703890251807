.footer {
  padding-top: 178px;
  padding-bottom: 60px;
  background-color: #161615;

  &__container {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-flow: column wrap;
    }
  }

  &-logo {
    display: flex;
    flex-wrap: wrap;

    &__img {
      margin-right: 94px;

      @include mobile {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    &__descr {
      width: 40%;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      color: var(--light-color);
      opacity: 0.4;

      @include mobile {
        width: 100%;
      }
    }

    @include mobile {
      margin-bottom: 30px;
    }
  }

  @include high-desktop {
    padding-top: 100px;
  }
}

.franch {
  margin-right: 39px;
  font-family: "suisse", serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  color: var(--light-color);
  transition: color 0.1s ease-in-out;

  &:hover {
    color: var(--orange-color);
    transition: color 0.1s ease-in-out;
  }

  &__img {
    margin-bottom: 15px;
  }

  &__txt {
    display: block;
  }
}
